import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from "../components/ui/Card";
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";

function SpecialServices(props) {
  const { t } = useTranslation();

  return (
    <div className={"card"}>
      <h2 className={"mb-[30px]"} data-aos="fade-up" data-aos-delay={'100'}>
        {t('SPECIAL_SERVICES')}
      </h2>
      <div className={"grid lg:grid-cols-3 lg:gap-[20px] gap-[10px] lg:mb-[30px] mb-[10px]"}>
        <div data-aos="fade-up" data-aos-delay={'200'}>
          <Card
            title={t('MEDICAL_PEDICURE_TITLE')}
            text={t('MEDICAL_PEDICURE_TEXT')}
            image={'ss1.png'}
          />
        </div>
        <div data-aos="fade-up" data-aos-delay={'300'}>
          <Card
            title={t('EQUIPMENT_SUPPLY_TITLE')}
            text={t('EQUIPMENT_SUPPLY_TEXT')}
            image={'ss2.png'}
          />
        </div>
        <div data-aos="fade-up" data-aos-delay={'400'}>
          <Card
            title={t('SHOWER_BATH_ASSISTANT_TITLE')}
            text={t('SHOWER_BATH_ASSISTANT_TEXT')}
            image={'ss3.png'}
          />
        </div>
      </div>
      <GoOnForm data-aos="fade-up" data-aos-delay={'300'}>
        <Button size={'lg'} label={t('BECOME_CLIENT')} className={"sm:w-fit w-full"}/>
      </GoOnForm>
    </div>
  );
}

export default SpecialServices;
