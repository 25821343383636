import React from 'react';
import clsx from "clsx";
import Header from "../components/core/Header";
import MainBlock from "../blocks/MainBlock";
import Why from "../blocks/Why";
import Instructions from "../blocks/Instructions";
import Form from "../blocks/Form";
import Faq from "../blocks/Faq";
import Footer from "../components/core/Footer";

function About(props) {
  return (
    <div className={clsx(' relative container  flex flex-col lg:gap-[40px] gap-[10px] lg:py-[40px] py-[10px]')}>
      <Header />

      <MainBlock />

      <Why />

      <Instructions />

      <Form />

      <Faq />

      <Footer />
    </div>
  );
}

export default About;