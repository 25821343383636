import React, {useEffect, useState} from 'react';

function changeTheme(isDark) {
  if (isDark) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
}

function ThemeSwitcher(props) {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      const isDark = savedTheme === 'dark';
      setDarkMode(isDark);
      changeTheme(isDark);
    }
  }, []);

  const toggleTheme = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    changeTheme(newDarkMode);
    localStorage.setItem('theme', newDarkMode ? 'dark' : 'light');
  };

  return (
    <div
      className="w-12 rounded-full h-6 bg-primary cursor-pointer flex items-center px-1/2 relative select-none"
      onClick={toggleTheme}
    >
      <div
        className={`${
          darkMode ? 'translate-x-6' : 'translate-x-1'
        } h-5 w-5 bg-dark rounded-full relative bg-white transform transition-transform duration-300`}
      />

      <span
        className={`text-center text-sm transition-all duration-300 flex-shrink ml-auto absolute top-[2px]  ${
          !darkMode ? 'ml-1/4 left-[4px]' : 'left-[25px] mr-1/4'
        }`}
        role="img"
        aria-label="theme emoji"
      >
                {darkMode ? '🌜' : '🌞'}
            </span>
    </div>
  );
}

export default ThemeSwitcher;