import React, {useEffect, useState} from 'react';
import {handleScroll} from "../../utils/handleScroll";
import Burger from "../ui/Burger";
import clsx from 'clsx'
import ThemeSwitcher from "../ui/ThemeSwitcher";
import Button from "../ui/Button";
import {Link} from "react-router-dom";
import I18Switcher from "../ui/i18Switcher";
import {useTranslation} from "react-i18next";

const Header = ({transparent}) => {
  const { t } = useTranslation();
  const [isActiveBurger, setIsActiveBurger] = useState(false)
  const navItems = [
    {
      to: '/yourPlan',
      name: 'Header.navItems.yourPlan',
    },
    {
      to: 'instructions',
      name: 'Header.navItems.cards',
    },
    {
      to: 'form',
      name: 'Header.navItems.contacts',
    },
    {
      to: 'why',
      name: 'Header.navItems.whoWeServe',
    },
    {
      to: "faq",
      name: 'Header.navItems.faq',
    }
  ];

  const handleClickLink = (e, to) => {
    handleScroll(e, to)
    setIsActiveBurger(false)
  }

  useEffect(() => {
    if(isActiveBurger){
      document.querySelector('html').classList.add('blocked')
    }else{
      document.querySelector('html').classList.remove('blocked')
    }
  }, [isActiveBurger])

  return (
    <header
      className={clsx(" rounded-base transition-colors", {'bg-gray': !transparent})}
    >
      <div className={clsx("container mx-auto   w-full flex gap-2 justify-between items-center", {'lg:p-[40px] p-[15px]': !transparent})}>
        <Link to={'/'}  className="flex items-center relative z-50 relative">
          <img src="/logo.png" alt="Logo" className="" />
        </Link>


        <nav className={clsx('flex lg:static  lg:pointer-events-auto z-40 point-event-none  fixed top-0 left-0 lg:w-fit w-full lg:flex-row flex-col items-center lg:pt-0 pt-[125px] top lg:gap-[60px] gap-[40px] ml-auto mr-[50px] transition-all duration-500', {
          "min-h-[100vh] opacity-100 bg-gray ": isActiveBurger,
          "sm:min-h-[43px] min-h-[43px] rounded-base lg:opacity-100 opacity-0  lg:translate-y-0 translate-y-[-120%]": !isActiveBurger
        })}>
          {navItems.map((item, index) => (
            item.to === '/yourPlan' ? (
              <Link key={index} to={item.to} className={clsx("hover:text-primary flex items-center lg:text-base text-lg lg:p-0 p-2 lg:uppercase lg:normal-case", { 'text-black': !transparent, 'navTransparentLink': transparent, 'active': isActiveBurger })}>
                {t(item.name)}
              </Link>
            ) : (
              <a onClick={(e) => handleClickLink(e, item.to)} key={index} href={`#${item.to}`}
                 className={clsx("hover:text-primary flex items-center lg:text-base text-lg lg:p-0 p-2 lg:uppercase lg:normal-case", { 'text-black': !transparent, 'navTransparentLink': transparent, 'active': isActiveBurger })}>
                {t(item.name)}
              </a>
            )
          ))}

          <div className={"w-full grow h-full flex flex-col pb-4 container lg:hidden block"}>
            <Button size={"lg"} className={"lg:hidden block w-full"} label={t('Header.buttonLabel')}></Button>

            <div className={"flex  justify-between items-end w-full px-3 pt-5 mt-auto grow"}>
              <div className={"flex flex-col gap-2"}>
                <a href="#">{t('Header.cookie')}</a>
                <a href="#">{t('Header.privacyPolicy')}</a>
              </div>
              <div>@2024</div>
            </div>
          </div>
        </nav>

        <div className={'flex items-center relative z-50'}>

          <div className={"mr-[20px] lg:block hidden"}><I18Switcher/></div>

          <ThemeSwitcher/>

          <Burger active={isActiveBurger} isBlack={true} onClick={() => setIsActiveBurger(!isActiveBurger)}/>
        </div>

      </div>
    </header>
  );
};

export default Header;
