import React from 'react';
import clsx from "clsx";

function Button({children, type, disabled, label, onClick, className, color = 'def', full, size = 'def'}) {
  const colors = {
    'red': "bg-red  hover:shadow-lg",
    "def": 'bg-primary  hover:shadow-custom-green'
  }

  const sizes = {
    'def': 'py-[10px] px-[22px]',
    'lg': 'py-[17px] lg:px-[40px] px-[20px]'
  }

  return (
    <button disabled={disabled} onClick={onClick} type={type} className={
      clsx(
        "all-text-white  rounded-lg uppercase font-bold  transition duration-300",
        colors[color],
        className,
        sizes[size],
        {
          "w-full": full,
          "w-fit": !full
        }
      )}>
      {label}
    </button>
  );
}

export default Button;