import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";

function WhoWeItem({ title, text }) {
  return (
    <div className={"glass-black sm:w-fit px-[20px] py-[18px] w-full"}>
      <div className={"flex gap-2"}>
        <img src={'/cbxRed.svg'} alt="icon"/>
        <h4 className={"mb-1 pt-[3px]"}>{title}</h4>
      </div>
      <div>{text}</div>
    </div>
  );
}

function WhoWe(props) {
  const { t } = useTranslation();

  return (
    <div className={"card all-text-white bg-primary relative"}>
      <div className={"flex flex-col gap-[10px] min-h-[560px] relative z-10"}>
        <div data-aos="fade-up" data-aos-delay={'100'} className={"uppercase font-bold "}>{t('WHERE_WE_ARE')}</div>
        <h2 data-aos="fade-up" data-aos-delay={'200'} className={"mb-[20px] mt-[10px] lg:max-w-[45%]"}>{t('SERVICE_AREA_WHO_WE_SERVE')}</h2>
        <img src="/map.png" data-aos="data-aos=" className={"lg:hidden block max-h-[400px] w-fit object-cover translate-x-[20px]"} alt="map"/>
        <div data-aos="fade-up" data-aos-delay={'300'}>
          <WhoWeItem text={t('ANY_AGE')} title={t('PEOPLE_WITH_MEDICARE')}/>
        </div>
        <div data-aos="fade-up" data-aos-delay={'400'}>
          <WhoWeItem text={t('LOS_ANGELES')} title={t('AREA')}/>
        </div>
        <div className={"mt-auto"}></div>
        <GoOnForm data-aos="fade-up" data-aos-delay={'500'}>
          <Button label={t('SUBMIT_REQUEST')} color={"red"} className={"lg:w-fit w-full"} size={'lg'}/>
        </GoOnForm>
      </div>

      <img src="/map.png" data-aos="fade-down-left" className={"absolute lg:block hidden top-0 right-0"} alt="map"/>
    </div>
  );
}

export default WhoWe;
