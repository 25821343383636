import React from 'react';
import FaqItem from "../components/ui/FaqItem";
import FaqBlock from "../components/core/FaqBlock";
import { useTranslation } from 'react-i18next';

function Faq(props) {
  const { t } = useTranslation();

  const data = [
    {
      title: t('Faq2.changePlanTitle'),
      text: t('Faq2.changePlanText')
    },
    {
      title: t('Faq2.whyChangeTitle'),
      text: t('Faq2.whyChangeText')
    }
  ]

  return (
    <FaqBlock
      data={data}
    />
  );
}

export default Faq;
