import React from 'react';
import { useTranslation } from 'react-i18next';

function InstructionsItem({title, number, image, text}) {
  return (
    <div data-aos="fade-up" data-aos-delay={number * 100} style={{background: `url('${image}') center/cover no-repeat`}} className={"rounded-base overlay-after relative px-[20px] sm:py-[25px] py-[20px] sm:min-h-[394px] min-h-[85vw]"}>
      <div className={"relative z-[5] flex flex-col h-full"}>
        <div className={'flex items-center gap-[10px]'}>
          <h3 className={"w-[35px] h-[35px] bg-primary rounded-full all-text-white text-center flex items-center justify-center shrink-0"}>{number}</h3>
          <h2 className={'all-text-white'}>{ title }</h2>
        </div>
        <div className={"flex flex-col gap-[20px] all-text-white mt-auto"}>
          { text.map((el, index) => (
            <div key={'text' + index}>{el}</div>
          )) }
        </div>
      </div>
    </div>
  )
}

function Instructions(props) {
  const { t } = useTranslation();

  const data = [
    {
      image: '/ins1.png',
      title: t('INSTRUCTION_1_TITLE'),
      text: [
        t('INSTRUCTION_1_TEXT_1'),
        t('INSTRUCTION_1_TEXT_2')
      ]
    },
    {
      image: '/ins2.png',
      title: t('INSTRUCTION_2_TITLE'),
      text: [
        t('INSTRUCTION_2_TEXT')
      ]
    },
    {
      image: '/ins3.png',
      title: t('INSTRUCTION_3_TITLE'),
      text: [
        t('INSTRUCTION_3_TEXT')
      ]
    },
    {
      image: '/ins4.png',
      title: t('INSTRUCTION_4_TITLE'),
      text: [
        t('INSTRUCTION_4_TEXT')
      ]
    },
    {
      image: '/ins5.png',
      title: t('INSTRUCTION_5_TITLE'),
      text: [
        t('INSTRUCTION_5_TEXT')
      ]
    },
    {
      image: '/ins6.png',
      title: t('INSTRUCTION_6_TITLE'),
      text: [
        t('INSTRUCTION_6_TEXT')
      ]
    },
  ];

  return (
    <div id={'instructions'} className={"card"}>
      <h2 className={"mb-[30px]"}>{t('INSTRUCTIONS_TITLE')}</h2>
      <div className={"grid xl:grid-cols-3 lg:grid-cols-2 sm:gap-[20px] gap-[10px]"}>
        {data.map((el, index) => (
          <InstructionsItem
            key={index}
            number={index + 1}
            title={el.title}
            image={el.image}
            text={el.text}
          />
        ))}
      </div>
    </div>
  );
}

export default Instructions;
