import React from 'react';
import clsx from "clsx";
import FirstHomeBlock from "../blocks/FirstHomeBlock";
import OurAdvantages from "../blocks/OurAdvantages";
import SpecialServices from "../blocks/SpecialServices";
import Terapist from "../blocks/Terapist";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Nursing from "../blocks/Nursing";
import HomeHealth from "../blocks/HomeHealth";
import WhoWe from "../blocks/WhoWe";
import Contacts from "../blocks/Contacts";
import FaqMain from "../blocks/FaqMain";
import Footer from "../components/core/Footer";


function Home(props) {
  return (
    <div className={clsx(' relative container  flex flex-col lg:gap-[40px] gap-[10px] py-[40px] ')}>
      <FirstHomeBlock />

      <div id={'instructions'}>
        <OurAdvantages />
      </div>

      <SpecialServices />

      <Terapist />

      <Nursing />

      <HomeHealth />

      <WhoWe />

      <Contacts />

      <FaqMain />

      <Footer />
    </div>
  );
}

export default Home;