import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";

function OurAdvItem({ name }) {
  return (
    <div className={"flex items-center gap-[5px]"}>
      <img src="/cbx.svg" alt=""/>
      <span>{name}</span>
    </div>
  )
}

function OurAdvantages(props) {
  const { t } = useTranslation();

  return (
    <div className={"card grid lg:grid-cols-2 sm:gap-[30px] gap-[20px]"}>
      <div>
        <div className={"text-primary mb-[20px] font-bold"} data-aos="fade-up" data-aos-delay={'100'}>
          {t('OUR_ADVANTAGES')}
        </div>
        <h2 data-aos="fade-up" data-aos-delay={'200'}>
          {t('CARE_AND_COMFORT')}
        </h2>
        <div className={"grid sm:grid-cols-2 sm:gap-[30px] gap-[20px] my-[55px]"} data-aos="fade-up" data-aos-delay={'300'}>
          <OurAdvItem name={t('HIGH_QUALITY_SPECIALISTS')}/>
          <OurAdvItem name={t('FAST_SERVICE')}/>
          <OurAdvItem name={t('BONUS_CARDS')}/>
          <OurAdvItem name={t('PERSONALIZED_APPROACH')}/>
        </div>

        <GoOnForm data-aos="fade-up" data-aos-delay={'400'}>
          <Button label={t('WHAT_IS_HOME_CARE')} size={'lg'} color={'red'} className={"sm:w-fit w-full"}/>
        </GoOnForm>
      </div>
      <div>
        <img className={"rounded-base"} src="/img1.png" alt=""/>
      </div>
    </div>
  );
}

export default OurAdvantages;
