import React from 'react';
import { useTranslation } from 'react-i18next';
import SliderCards from "../components/core/Slider";
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";

function Nursing(props) {
  const { t } = useTranslation();

  const cards = [
    {
      title: t('SKILLED_OBSERVATION_TITLE'),
      text: t('SKILLED_OBSERVATION_TEXT'),
      image: 'nurs1.jpg'
    },
    {
      title: t('DIABETIC_MANAGEMENT_TITLE'),
      text: t('DIABETIC_MANAGEMENT_TEXT'),
      image: 'nurs2.jpg'
    },
    {
      title: t('WOUND_CARE_TITLE'),
      text: t('WOUND_CARE_TEXT'),
      image: 'nurs3.jpg'
    },
    {
      title: t('MEDICATION_SUPERVISION_TITLE'),
      text: t('MEDICATION_SUPERVISION_TEXT'),
      image: 'nurs4.jpg'
    },
    {
      title: t('INTRAVENOUS_THERAPY_TITLE'),
      text: t('INTRAVENOUS_THERAPY_TEXT'),
      image: 'nurs5.jpg'
    },
    {
      title: t('TOTAL_PARENTERAL_NUTRITION_TITLE'),
      text: t('TOTAL_PARENTERAL_NUTRITION_TEXT'),
      image: 'nurs6.jpg'
    },
    {
      title: t('PAIN_MANAGEMENT_TITLE'),
      text: t('PAIN_MANAGEMENT_TEXT'),
      image: 'nurs7.jpg'
    },
    {
      title: t('RESPIRATORY_TREATMENTS_TITLE'),
      text: t('RESPIRATORY_TREATMENTS_TEXT'),
      image: 'nurs8.jpg'
    },
    {
      title: t('PATIENT_FAMILY_HEALTH_TEACHING_TITLE'),
      text: t('PATIENT_FAMILY_HEALTH_TEACHING_TEXT'),
      image: 'nurs9.jpg'
    },
    {
      title: t('INCONTINENCE_CARE_TITLE'),
      text: t('INCONTINENCE_CARE_TEXT'),
      image: 'nurs10.jpg'
    },
  ];

  return (
    <div className={"card"}>
      <SliderCards
        title={t('NURSING_SERVICE')}
        data={cards}
      />
      <div className={"mt-[30px]"}></div>
      <GoOnForm data-aos="fade-up">
        <Button label={t('BECOME_CLIENT')} size={'lg'} className={"sm:w-fit w-full"}/>
      </GoOnForm>
    </div>
  );
}

export default Nursing;
