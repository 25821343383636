import {useEffect} from "react";
import 'aos/dist/aos.css';
import AOS from 'aos';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Длительность анимации в миллисекундах
    });
  }, []);

  return (
    <Router className={'App'}>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/yourPlan" element={<About/>}/>
      </Routes>
    </Router>
  );
}

export default App;
