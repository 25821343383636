import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import clsx from 'clsx';
import { botToken, chatId } from "../utils/telegramData";

function Contacts(props) {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: '',
    dob: '',
    phone: '',
    bonusCard: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    dob: '',
    phone: '',
    bonusCard: ''
  });
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('error');
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setForm({
      name: '',
      dob: '',
      phone: '',
      bonusCard: ''
    });
    setErrors({
      name: '',
      dob: '',
      phone: '',
      bonusCard: ''
    });
  };

  const validateForm = () => {
    const newErrors = {
      name: '',
      dob: '',
      phone: '',
      bonusCard: ''
    };
    const dobRegex = /^\d{2} \/ \d{2} \/ \d{4}$/;
    let isValid = true;

    if (form.name.trim() === '') {
      newErrors.name = t('NAME_REQUIRED');
      isValid = false;
    }
    if (form.dob.trim() === '') {
      newErrors.dob = t('DOB_REQUIRED');
      isValid = false;
    } else if (!dobRegex.test(form.dob)) {
      newErrors.dob = t('INVALID_DOB_FORMAT');
      isValid = false;
    }
    if (form.bonusCard.trim() === '') {
      newErrors.bonusCard = t('BONUS_CARD_REQUIRED');
      isValid = false;
    }
    if (form.phone.trim() === '') {
      newErrors.phone = t('PHONE_REQUIRED');
      isValid = false;
    } else if (!/^\+1 \d{3}-\d{3}-\d{4}$/.test(form.phone)) {
      newErrors.phone = t('INVALID_PHONE_NUMBER');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const setError = () => {
      setStatusMessage('error');
      setIsShowMessage(true);
      setTimeout(() => {
        setIsShowMessage(false);
      }, 3000);
    };

    const setSuccess = () => {
      setStatusMessage('success');
      setIsShowMessage(true);
      setTimeout(() => {
        setIsShowMessage(false);
      }, 3000);
    };

    try {
      const message = `${t('FIRST_LAST_NAME')}: ${form.name}\n${t('DOB')}: ${form.dob}\n${t('PHONE_NUMBER')}: ${form.phone}\n${t('BONUS_CARD')}: ${form.bonusCard}`;
      const res = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message
        })
      });

      if (res.status !== 200) {
        setError();
        return;
      }
      setSuccess();
      resetForm();
    } catch (err) {
      setError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id={'form'} className={"card grid lg:grid-cols-2 gap-[20px]"}>
      <div className={'bg-red all-text-white rounded-base px-[20px] sm:py-[27px] py-[20px]'} data-aos="fade-up">
        <div className={"lg:flex justify-between items-center gap-2"}>
          <h2 className={"mb-[10px]"}>{t('CONTACTS')}</h2>
          <a href={'tel:+14243331176'}>+1 (424) 333-1176</a>
        </div>
        <div className={"flex lg:flex-row flex-col items-center lg:items-start justify-between gap-2 items-end lg:mt-[40px] mt-[20px]"}>
          <div>
            <h2 data-aos="fade-up">{t('WE_ACCEPT_ONLY_MEDICARE')}</h2>
            <p className={"max-w-[310px] mt-[10px]"}>{t('SUBMIT_REQUEST_VERIFY')}</p>
          </div>
          <div>
            <img src="/medLogo.png" alt=""/>
          </div>
        </div>
      </div>
      <form data-aos="fade-up" onSubmit={handleSubmit}>
        <div className={"xl:grid-cols-2 grid gap-[11px] mb-[10px]"}>
          <Input
            placeholder={t('FIRST_LAST_NAME')}
            value={form.name}
            onChange={(e) => {
              handleInputChange(e); validateForm();
            }}
            name={'name'}
            error={errors.name}
          />
          <Input
            placeholder={t('DOB_PLACEHOLDER')}
            label={t('DATE_OF_BIRTH')}
            mask={'99 / 99 / 9999'}
            value={form.dob}
            onChange={(e) => {
              handleInputChange(e); validateForm();
            }}
            name={'dob'}
            error={errors.dob}
          />
          <Input
            placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
            label={t('PHONE_NUMBER')}
            value={form.phone}
            mask={'+1 999-999-9999'}
            onChange={(e) => {
              handleInputChange(e); validateForm();
            }}
            name={'phone'}
            error={errors.phone}
          />
          <Input
            label={t('BONUS_CARD')}
            placeholder={t('BONUS_CARD_PLACEHOLDER')}
            value={form.bonusCard}
            onChange={(e) => {
              handleInputChange(e); validateForm();
            }}
            name={'bonusCard'}
            error={errors.bonusCard}
          />
        </div>

        <Button label={loading ? t('LOADING') : t('SEND')} disabled={loading} size={'lg'} full={true} type={'submit'} />
      </form>

      <div
        className={clsx('message fixed transition-all duration-500 top-[10px] text-white py-3 px-5 rounded-lg left-1/2 translate-x-[-50%]',
          {
            'translate-y-0': isShowMessage,
            "translate-y-[-200px]": !isShowMessage,
            'bg-primary': statusMessage === 'success',
            'bg-red': statusMessage === 'error',
          })}
      >
        {statusMessage === 'success' ? t('SUCCESS_MESSAGE') : t('ERROR_MESSAGE')}
      </div>
    </div>
  );
}

export default Contacts;
