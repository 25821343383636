import React, { useState, useRef, useEffect } from 'react';
import Plus from "../icon/Plus";
import clsx from "clsx";

function FaqItem({ title, text, aos, delay }) {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    // Устанавливаем высоту контента
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [text]);


  return (
    <div data-aos={aos} data-aos-delay={delay} className="faqItem" onClick={() => setIsOpen(!isOpen)}>
      <div className="faqItem-head flex gap-3 sm:items-center justify-between">
        <h4>{title}</h4>
        <div className="sm:w-[50px] w-[37px] sm:h-[50px] h-[37px] shrink-0 bg-red flex items-center justify-center rounded-lg">
          <div className={clsx('transition-all duration-500', { "rotate-45": isOpen })}>
            <Plus />
          </div>
        </div>
      </div>
      <div
        className={clsx("overflow-hidden transition-all duration-500", {
          "max-h-0": !isOpen,
        })}
        style={{ maxHeight: isOpen ? `${contentHeight}px` : '0px' }}
      >
        <div ref={contentRef} className="px-[20px] pb-[30px]">
          {text}
        </div>
      </div>
    </div>
  );
}

export default FaqItem;
