import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import Card from "../ui/Card";

function SliderCards({data, title}) {
  return (
    <div className={"relative"}>
      <h2 className={"mb-[-45px] sliderTitle"} data-aos="fade-up" data-aos-delay={'100'}>
        {title}
      </h2>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1.1}
        spaceBetween={10}
        navigation
        pagination={{
          clickable: true,
          type: 'progressbar', // Добавляем тип пагинации "progressbar"
        }}
        breakpoints={{
          700: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30
          },
        }}
        autoplay={{delay: 3000, disableOnInteraction: false}}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {data.map((el, i) => (
          <SwiperSlide>
            <div className="slide-content" data-aos="fade-up" data-aos-delay={i * 100}>
              <Card
                text={el.text}
                title={el.title}
                image={el.image}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SliderCards;
