import React from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";

function I18Switcher(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Смена языка
  };

  return (
    <div className="flex gap-2">
      <button style={{padding: '2px 7px'}} className={clsx("text-[13px] rounded-base font-medium bg-white text-black block p-2", {'opacity-30': currentLanguage !== 'zh'})} onClick={() => changeLanguage('zh')}>中文</button>
      <button style={{padding: '2px 7px'}} className={clsx("text-[13px] rounded-base font-medium bg-white text-black block p-2", {'opacity-30': currentLanguage !== 'en'})} onClick={() => changeLanguage('en')}>EN</button>
      <button style={{padding: '2px 7px'}} className={clsx("text-[13px] rounded-base font-medium bg-white text-black block p-2", {'opacity-30': currentLanguage !== 'ru'})} onClick={() => changeLanguage('ru')}>RU</button>
    </div>
  );
}

export default I18Switcher;