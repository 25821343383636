import React, { useState } from 'react';
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import clsx from 'clsx';
import {botToken, chatId} from "../utils/telegramData";
import { useTranslation } from 'react-i18next';

function Form(props) {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    name: '',
    address: '',
    phone: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    address: '',
    phone: ''
  });
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('error');
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setForm({
      name: '',
      address: '',
      phone: ''
    });
    setErrors({
      name: '',
      address: '',
      phone: ''
    });
  };

  const validateForm = () => {
    const newErrors = {
      name: '',
      address: '',
      phone: ''
    };
    let isValid = true;

    if (form.name.trim() === '') {
      newErrors.name = t('Form.nameRequired');
      isValid = false;
    }
    if (form.address.trim() === '') {
      newErrors.address = t('Form.addressRequired');
      isValid = false;
    }
    if (form.phone.trim() === '') {
      newErrors.phone = t('Form.phoneRequired');
      isValid = false;
    } else if (!/^\+1 \d{3}-\d{3}-\d{4}$/.test(form.phone)) {
      newErrors.phone = t('Form.phoneInvalid');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const setError = () => {
      setStatusMessage('error');
      setIsShowMessage(true);
      setTimeout(() => {
        setIsShowMessage(false);
      }, 3000);
    };

    const setSuccess = () => {
      setStatusMessage('success');
      setIsShowMessage(true);
      setTimeout(() => {
        setIsShowMessage(false);
      }, 3000);
    };

    try {
      const message = `${t('Form.name')}: ${form.name}\n${t('Form.phone')}: ${form.phone}\n${t('Form.address')}: ${form.address}`;
      const res = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message
        })
      });

      if (res.status !== 200) {
        setError();
        return;
      }
      setSuccess();
      resetForm();
    } catch (err) {
      setError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id={'form'} className={"card grid lg:grid-cols-2 gap-[20px]"}>
      <div className={'bg-red all-text-white rounded-base px-[20px] sm:py-[27px] py-[20px]'}>
        <h2 data-aos="fade-up" className={"mb-[10px]"}>{t('Form.troubleHeading')}</h2>
        <p data-aos="fade-up">{t('Form.troubleText')}</p>
      </div>
      <form data-aos="fade-up" onSubmit={handleSubmit}>
        <div className={"xl:grid-cols-2 grid gap-[11px] mb-[10px]"}>
          <Input
            placeholder={t('Form.namePlaceholder')}
            value={form.name}
            onChange={(e) => {
              handleInputChange(e); validateForm()
            }}
            name={'name'}
            error={errors.name}
          />
          <Input
            placeholder={t('Form.addressPlaceholder')}
            value={form.address}
            onChange={(e) => {
              handleInputChange(e); validateForm()
            }}
            name={'address'}
            error={errors.address}
          />
          <Input
            placeholder={t('Form.phonePlaceholder')}
            value={form.phone}
            mask={'+1 999-999-9999'}
            onChange={(e) => {
              handleInputChange(e); validateForm()
            }}
            name={'phone'}
            error={errors.phone}
          />
        </div>

        <Button label={loading ? t('Form.loading') : t('Form.submit')} disabled={loading} size={'lg'} full={true} type={'submit'} />
      </form>

      <div
        className={clsx('message fixed transition-all duration-500 top-[10px] text-white py-3 px-5 rounded-lg left-1/2 translate-x-[-50%]',
          {
            'translate-y-0': isShowMessage,
            "translate-y-[-200px]": !isShowMessage,
            'bg-primary': statusMessage === 'success',
            'bg-red': statusMessage === 'error',
          })}
      >
        {statusMessage === 'success' ? t('Form.successMessage') : t('Form.errorMessage')}
      </div>
    </div>
  );
}

export default Form;
