import React from 'react';
import InputMask from "react-input-mask"

function Input({placeholder,label, value, error, onChange, name, mask}) {
  return (
    <label className={"bg-white border h-fit border-1 border-black rounded-lg px-[20px] pb-[6px]"}>
      {error
        ? <span className={"text-red"} style={{fontSize: '10px'}}>{error}</span>
        : <span style={{fontSize: '10px'}}>{label || placeholder}</span>}

      {mask ?
        <InputMask
          mask={mask}
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          style={{color: 'var(--black)', outline: 'none', border: 'none', background: 'transparent', width: '100%'}}
        />
        :
        <input
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          style={{color: 'var(--black)', outline: 'none', border: 'none', background: 'transparent', width: '100%'}}
        />
      }
    </label>
  );
}

export default Input;
