import React from 'react';
import { useTranslation } from 'react-i18next';

function MainBlock(props) {
  const { t } = useTranslation();

  return (
    <div id={'mainBlock'} className={"lg:flex gap-[80px] lg:py-[60px] pt-[60px] pb-[40px]"}>
      <h1 data-aos="fade-up" data-aos-delay={'100'} className={'xl:max-w-fit lg:max-w-[567px] max-w-[355px]'}>
        <span className={'text-primary'}>{t('CHANGE_PLAN_TITLE_PART1')}</span> {t('CHANGE_PLAN_TITLE_PART2')}
        <span className={"text-primary pl-2"}>{t('CHANGE_PLAN_TITLE_PART3')}</span>
      </h1>
      <div className={"lg:mt-[20px] lg:mt-[40px] sm:min-w-[310px] max-w-[310px] lg:max-w-fit"}>
        <h4 data-aos="fade-up" data-aos-delay={'200'} className={"mb-[20px]"}>{t('WHY_CHANGE_TITLE')}</h4>
        <p data-aos="fade-up" data-aos-delay={'300'}>{t('WHY_CHANGE_TEXT')}</p>
      </div>
    </div>
  );
}

export default MainBlock;
