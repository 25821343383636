import React from 'react';
import Header from "../components/core/Header";
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";
import {useTranslation} from "react-i18next";
import I18Switcher from "../components/ui/i18Switcher";

function FirstHomeBlock(props) {
  const { t } = useTranslation();

  return (
    <div>
      <span className={"block lg:hidden mb-[20px]"}><Header /></span>

      <div className={"card all-text-white"} style={{background: `url('bg3.png') center/cover no-repeat`}}>
        <span className={"hidden lg:block"}><Header transparent={true}/></span>

        <div className={"grid lg:grid-cols-2 items-end"}>
          <div data-aos="fade-up" className={"flex flex-col"}>
            <h1 className={"mb-[10px] lg:mt-[75px] mt-[40px]"}>{t('FirstHomeBlock.header')}</h1>
            <h4 className={"lg:mb-[50px] lg:mb-[250px] mb-[50px]"}>{t('FirstHomeBlock.subHeader')}</h4>
            <p className={"lg:mb-[20px] mb-[150px]"}>{t('FirstHomeBlock.description')}</p>
            {/*<GoOnForm>*/}
            {/*  <Button label={t('FirstHomeBlock.join')} size={'lg'} color={'red'} className={"lg:w-fit w-[35%]"}/>*/}
            {/*</GoOnForm>*/}
            <div className={' lg:order-none -order-1 flex justify-between'}>
              <img className={"lg:mt-[150px] lg:w-fit w-[80px] h-fit"} src="/medLogo.png"
                   alt=""/>
              <div className={"mr-[20px] lg:hidden "}><I18Switcher/></div>
            </div>
          </div>
          <div className={"flex flex-col lg:items-end"}>
            <div data-aos="fade-up" data-aos-delay={'100'}>
              <h4 className={"mb-2 lg:block hidden"}>{t('FirstHomeBlock.callUs')}</h4>
              <a href={"tel:+14243331176"} className={"glass glass-hover block mb-[20px] lg:px-[30px] px-[18px] lg:py-[22px] py-[19px] lg:ml-0 ml-auto lg:mt-0 mt-[-58px]  lg:w-fit w-[62%] lg:text-base text-sm"}>{t('FirstHomeBlock.phoneNumber')}</a>
            </div>
            <div data-aos="fade-up" data-aos-delay={'200'} className={"glass flex items-center lg:w-fit w-full lg:px-[25px] px-[15px] lg:py-[40px] py-[15px]"}>
              <div className={'bg-red h-[80px] min-w-[60px] rounded-lg flex flex-col justify-between p-[10px]'} style={{fontSize: '12px'}}>
                <div>{t('FirstHomeBlock.ross')}</div>
                <div className={"font-bold"}>{t('FirstHomeBlock.rossValue')}</div>
              </div>
              <div className={"bg-primary lg:min-w-[130px] min-w-[115px] h-[80px] translate-x-[-14px] rounded-lg flex flex-col justify-between p-[10px]"} style={{fontSize: '12px'}}>
                <div>{t('FirstHomeBlock.groceryCard')}</div>
                <div className={"font-bold"}>{t('FirstHomeBlock.groceryCardValue')}</div>
              </div>
              <div className={"max-w-[140px] text-xs all-text-white"}>
                {t('FirstHomeBlock.freeFoodClothing')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstHomeBlock;
