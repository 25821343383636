import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from "../components/ui/Card";
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";

function HomeHealth(props) {
  const { t } = useTranslation();

  return (
    <div className={"card"}>
      <h2 className={"mb-[30px]"}>{t('HOME_HEALTH_AID_SERVICE')}</h2>
      <div className={"grid lg:grid-cols-3 lg:gap-[20px] gap-[10px] mb-[30px]"}>
        <div data-aos="fade-up" data-aos-delay={'100'}>
          <Card
            title={t('TOTAL_PERSONAL_CARE_TITLE')}
            text={t('TOTAL_PERSONAL_CARE_TEXT')}
            image={'HomeH1.jpg'}
          />
        </div>
        <div data-aos="fade-up" data-aos-delay={'200'}>
          <Card
            title={t('MEAL_PREPARATION_TITLE')}
            text={t('MEAL_PREPARATION_TEXT')}
            image={'HomeH2.jpg'}
          />
        </div>
        <div className={"flex items-center justify-center text-center text-primary lg:py-0 py-[50px]"} data-aos="fade-up" data-aos-delay={'200'}>
          <span className={'max-w-[200px]'}>{t('NEW_SERVICE_COMING')}</span>
        </div>
      </div>

      <GoOnForm data-aos="fade-up">
        <Button size={'lg'} label={t('BECOME_CLIENT')} className={"sm:w-fit w-full"}/>
      </GoOnForm>
    </div>
  );
}

export default HomeHealth;
