import React from 'react';
import { useTranslation } from 'react-i18next';
import { handleScroll } from "../../utils/handleScroll";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const navItems = [
    {
      to: '/yourPlan',
      name: t('NAV_YOUR_PLAN'),
    },
    {
      to: 'instructions',
      name: t('NAV_CARDS'),
    },
    {
      to: 'form',
      name: t('NAV_CONTACTS')
    },
    {
      to: 'why',
      name: t('NAV_WHO_WE_SERVE')
    },
    {
      to: "faq",
      name: t('NAV_FAQ')
    }
  ];

  return (
    <footer className="bg-gray rounded-base">
      <div className="container mx-auto lg:p-[40px] p-[20px]">
        <div className="flex lg:flex-row flex-col justify-between lg:items-center">
          <Link to={'/'} className="flex items-center">
            <img src="/logo.png" alt="Logo" className="" />
          </Link>
          <nav className="flex lg:flex-row flex-col lg:gap-[60px] gap-[20px] lg:mt-0 mt-[50px] lg:ml-auto ">
            {navItems.map((item, index) => (
              item.to === '/yourPlan'
                ? <Link to={item.to} key={index} className="text-black hover:text-primary">{item.name}</Link>
                : <a key={index} onClick={(e) => handleScroll(e, item.to)} href="#" className="text-black hover:text-primary">{item.name}</a>
            ))}
          </nav>
        </div>
        <div className={"flex lg:flex-row flex-row-reverse lg:items-center items-end justify-between mt-[40px]"}>
          <div>{t('FOOTER_COPYRIGHT')}</div>
          <div className={'flex lg:flex-row flex-col lg:gap-[50px] gap-[20px]'}>
            <a href="">{t('FOOTER_COOKIE')}</a>
            <a href="">{t('FOOTER_PRIVACY_POLICY')}</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
