import React from 'react';
import SliderCards from "../components/core/Slider";
import Button from "../components/ui/Button";
import GoOnForm from "../components/ui/GoOnForm";
import {useTranslation} from "react-i18next";

function Terapist(props) {
  const { t } = useTranslation();

  const cards = [
    {
      title: t('MASSAGE_TITLE'),
      text: t('MASSAGE_TEXT'),
      image: 'therapist1.jpg'
    },
    {
      title: t('THERAPEUTIC_EXERCISES_TITLE'),
      text: t('THERAPEUTIC_EXERCISES_TEXT'),
      image: 'therapist2.jpg'
    },
    {
      title: t('ASSESSMENT_REHABILITATION_TITLE'),
      text: t('ASSESSMENT_REHABILITATION_TEXT'),
      image: 'therapist3.jpg'
    },
    {
      title: t('TRANSFER_GAIT_TRAINING_TITLE'),
      text: t('TRANSFER_GAIT_TRAINING_TEXT'),
      image: 'therapist4.jpg'
    },
    {
      title: t('PATIENT_FAMILY_TEACHING_TITLE'),
      text: t('PATIENT_FAMILY_TEACHING_TEXT'),
      image: 'therapist5.jpg'
    },
  ];

  return (
    <div className={"card"}>
      <SliderCards
        title={t('PHYSICAL_THERAPIST')}
        data={cards}
      />
      <div className={"mt-[30px]"}></div>
      <GoOnForm data-aos="fade-up">
        <Button label={t('BECOME_CLIENT')} size={'lg'}/>
      </GoOnForm>
    </div>
  );
}

export default Terapist;
