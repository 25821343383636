import React from 'react';
import Button from "../components/ui/Button";
import { handleScroll } from "../utils/handleScroll";
import { useTranslation } from 'react-i18next';

function Why(props) {
  const { t } = useTranslation();

  return (
    <div id={'why'} className={"card grid lg:grid-cols-2 lg:gap-[30px] gap-[20px]"}>
      <div className={"flex flex-col lg:min-h-[590px]"}>
        <h2 data-aos="fade-up" className={"lg:mb-[40px] mb-[20px]"}>{t('WHY_TITLE')}</h2>
        <h4 data-aos="fade-up" className={"lg:mb-[20px] mb-[10px]"}>{t('WHY_SUBTITLE')}</h4>
        <p data-aos="fade-up" className={"lg:mb-auto lg:mb-[20px] grow-1"}>{t('WHY_TEXT')}</p>

        <a data-aos="fade-up" className={'hidden lg:block'} href="form" onClick={(e) => handleScroll(e, 'form')}>
          <Button label={t('HOW_TO_CHANGE_PLAN')} color={'red'} size={'lg'}/>
        </a>
      </div>
      <div data-aos="fade-up" className={"bg-black rounded-base flex items-end justify-center lg:py-[30px] py-[20px] px-[20px]"}>
        <img src="/whyImage.png" alt=""/>
      </div>

      <a className={'lg:hidden block'} href="form" onClick={(e) => handleScroll(e, 'form')}>
        <Button label={t('HOW_TO_CHANGE_PLAN')} color={'red'} size={'lg'} full={true}/>
      </a>
    </div>
  );
}

export default Why;
