import React from 'react';
import { useTranslation } from 'react-i18next';
import FaqItem from "../components/ui/FaqItem";
import FaqBlock from "../components/core/FaqBlock";

function FaqMain(props) {
  const { t } = useTranslation();

  const data = [
    {
      title: t('FAQ_FREQUENCY_TITLE'),
      text: t('FAQ_FREQUENCY_TEXT')
    },
    {
      title: t('FAQ_FREE_CARDS_TITLE'),
      text: t('FAQ_FREE_CARDS_TEXT')
    },
    {
      title: t('FAQ_DIFFERENCE_TITLE'),
      text: t('FAQ_DIFFERENCE_TEXT')
    },
  ]

  return (
    <FaqBlock
      data={data}
    />
  );
}

export default FaqMain;
