import React from 'react';
import clsx from "clsx";

function Burger({active, onClick, isBlack}) {
  const burgerClass = clsx(
    'burger ',
    {
      'active': active,
      'isBlack': isBlack
    }
  );

  return (
    <div className={burgerClass} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Burger;