import React from 'react';
import FaqItem from "../ui/FaqItem";
import {useTranslation} from "react-i18next";

function FaqBlock({data}) {
  const { t } = useTranslation();

  return (
    <div id={'faq'} className={"rounded-base relative overlay-after all-text-white cardPadding"}
         style={{background: `url('/faqBg.png') center/cover no-repeat`}}>
      <div className={"relative z-10"}>
        <h2 data-aos="fade-up" className={"mb-[30px]"}>{t('faq-title')} </h2>

        <div className={"flex flex-col gap-[10px]"}>
          {data.map((el, index) => (
            <FaqItem
              aos="fade-up"
              delay={(index + 1) * 100}
              title={el.title}
              text={el.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FaqBlock;