import React from 'react';
import {handleScroll} from "../../utils/handleScroll";

function GoOnForm({children}) {
  return (
    <a href={'#form'} onClick={(e) => handleScroll(e, 'form')}>
      {children}
    </a>
  );
}

export default GoOnForm;